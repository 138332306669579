import { Box, styled } from '@mui/material';

export const PlaceholderContainer = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.others.placeholderBorder}`,
  borderRadius: theme.shape.borderRadius,
  width: '100%',
  minHeight: 502,
  maxHeight: 502,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const DescriptionList = styled('ul')(({ theme }) => ({
  maxWidth: 350,
  listStyle: 'none',
  margin: 0,
  padding: 0,
  marginTop: theme.spacing(10),
  '& li': {
    marginBottom: theme.spacing(3),

    display: 'flex',
    alignitems: 'center',
    '&:before': {
      content: '"\\2022"',
      color: theme.palette.text.secondary,
      display: 'inline-block',
    },
  },
}));
