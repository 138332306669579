import { FC } from 'react';
import { SvgIconProps } from '@mui/material';

import { BaseIcon } from '../base-icon/base-icon';

const PdfFile: FC<SvgIconProps> = props => (
  <BaseIcon {...props}>
    <path
      d="M16 0C13.8 0 12 1.8 12 4V60C12 62.2 13.8 64 16 64H56C58.2 64 60 62.2 60 60V16L44 0H16Z"
      fill="white"
    />
    <path d="M48 16H60L44 0V12C44 14.2 45.8 16 48 16Z" fill="#A8B6CD" />
    <path d="M60 28L48 16H60V28Z" fill="#EDF0F5" />
    <path
      d="M52 52C52 53.1 51.1 54 50 54H6C4.9 54 4 53.1 4 52V32C4 30.9 4.9 30 6 30H50C51.1 30 52 30.9 52 32V52Z"
      fill="#A8B6CD"
    />
    <path
      d="M12.718 37.894C12.718 37.366 13.134 36.79 13.804 36.79H17.498C19.578 36.79 21.45 38.182 21.45 40.85C21.45 43.378 19.578 44.786 17.498 44.786H14.828V46.898C14.828 47.602 14.38 48 13.804 48C13.276 48 12.718 47.602 12.718 46.898V37.894ZM14.828 38.804V42.788H17.498C18.57 42.788 19.418 41.842 19.418 40.85C19.418 39.732 18.57 38.804 17.498 38.804H14.828Z"
      fill="white"
    />
    <path
      d="M24.582 48.0001C24.054 48.0001 23.478 47.7121 23.478 47.0101V37.9261C23.478 37.3521 24.054 36.9341 24.582 36.9341H28.244C35.552 36.9341 35.392 48.0001 28.388 48.0001H24.582ZM25.59 38.8861V46.0501H28.244C32.562 46.0501 32.754 38.8861 28.244 38.8861H25.59Z"
      fill="white"
    />
    <path
      d="M37.984 39.0141V41.5561H42.062C42.638 41.5561 43.214 42.1321 43.214 42.6901C43.214 43.2181 42.638 43.6501 42.062 43.6501H37.984V47.0081C37.984 47.5681 37.586 47.9981 37.026 47.9981C36.322 47.9981 35.892 47.5681 35.892 47.0081V37.9241C35.892 37.3501 36.324 36.9321 37.026 36.9321H42.64C43.344 36.9321 43.76 37.3501 43.76 37.9241C43.76 38.4361 43.344 39.0121 42.64 39.0121H37.984V39.0141Z"
      fill="white"
    />
    <path d="M50 54H12V56H50C51.1 56 52 55.1 52 54V52C52 53.1 51.1 54 50 54Z" fill="#EDF0F5" />
  </BaseIcon>
);

export { PdfFile };
