import { FC, ReactNode, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthStatusContext } from 'src/contexts';
import { AppRoutes } from 'src/common';

interface IProtectedRoutesProps {
  children: ReactNode;
}

const ProtectedRoute: FC<IProtectedRoutesProps> = ({ children }) => {
  const { isAuthorized, isInitialChecking } = useContext(AuthStatusContext);

  if (!isAuthorized && !isInitialChecking) {
    return <Navigate to={AppRoutes.LOGIN} />;
  }

  return <>{children}</>;
};

export { ProtectedRoute };
