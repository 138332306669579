import { FC, useState } from 'react';
import { SubmitHandler, DefaultValues, useForm } from 'react-hook-form';
import { useAmplify } from 'src/hooks';
import { Visibility, VisibilityOff } from 'src/components/atoms';
import { Input } from 'src/components/atoms';
import { Typography, InputAdornment, IconButton, Box, Button } from '@mui/material';
import { StyledLoginFormContainer, StyledForm } from './login-form.styles';

const DEFAULT_LOGIN_FORM_PAYLOAD: DefaultValues<IFormValues> = {
  email: '',
  password: '',
};

interface IFormValues {
  email: string;
  password: string;
}

const LoginForm: FC = () => {
  const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false);
  const { control, handleSubmit, formState } = useForm<IFormValues>({
    defaultValues: DEFAULT_LOGIN_FORM_PAYLOAD,
  });
  const { handleLogin, hasLoginError, loading } = useAmplify();

  const handleSumbitLogin: SubmitHandler<IFormValues> = values => {
    handleLogin(values);
  };

  const handleShownPassword = () => {
    setIsPasswordShown(prevState => !prevState);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <StyledLoginFormContainer elevation={0}>
      <StyledForm onSubmit={handleSubmit(handleSumbitLogin)}>
        <Typography variant="h4" marginBottom={10}>
          Welcome to Hamlet!
        </Typography>
        <Input
          id="email"
          label="Email"
          type="email"
          placeholder="example@gmail.com"
          control={control}
          name="email"
        />
        <Input
          id="password"
          label="Password"
          type={isPasswordShown ? 'text' : 'password'}
          placeholder="••••••"
          control={control}
          name="password"
          marginBottom={hasLoginError ? 4 : 12}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleShownPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {isPasswordShown ? (
                  <VisibilityOff width={24} height={24} />
                ) : (
                  <Visibility width={24} height={24} />
                )}
              </IconButton>
            </InputAdornment>
          }
        />

        {hasLoginError && (
          <Box sx={{ maxWidth: 292, marginBottom: 4 }}>
            <Typography variant="subtitle2" color="others.error">
              The email and password you entered did not match our records. Please double-check and
              try again.
            </Typography>
          </Box>
        )}

        <Button
          color="primary"
          size="large"
          variant="contained"
          fullWidth
          type="submit"
          disabled={!formState.isDirty || !formState.isValid || loading}
        >
          Sign in
        </Button>
      </StyledForm>
    </StyledLoginFormContainer>
  );
};

export { LoginForm };
