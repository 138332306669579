import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Hub, Auth } from 'aws-amplify';

import { AuthStatusContext } from './context';

interface IAuthStatusProviderProps {
  children: ReactNode;
}

export const AuthStatusProvider: FC<IAuthStatusProviderProps> = ({ children }) => {
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const [isInitialChecking, setIsInitialChecking] = useState<boolean>(false);

  const handleCheckUser = useCallback(async () => {
    try {
      await Auth.currentAuthenticatedUser();
      setIsAuthorized(true);
    } catch {
      setIsAuthorized(false);
    } finally {
      setIsInitialChecking(false);
    }
  }, []);

  const handleUpdateAuthStatus = useCallback(async ({ payload: { event = '' } = {} }) => {
    switch (event) {
      case 'signIn':
        setIsAuthorized(true);
        break;
      case 'signOut':
        setIsAuthorized(false);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', handleUpdateAuthStatus);
    handleCheckUser();
    return unsubscribe;
  }, [handleCheckUser, handleUpdateAuthStatus]);

  const value = useMemo(
    () => ({ isAuthorized, isInitialChecking }),
    [isAuthorized, isInitialChecking],
  );

  return <AuthStatusContext.Provider value={value}>{children}</AuthStatusContext.Provider>;
};
