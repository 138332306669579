import { FC } from 'react';
import { useAmplify, useAuthStatus } from 'src/hooks';
import { Box, Button } from '@mui/material';
import { Logo, LogOut } from 'src/components/atoms';
import { StyledHeader } from './header.styles';

const Header: FC = () => {
  const { handleLogout } = useAmplify();
  const { isAuthorized } = useAuthStatus();

  return (
    <StyledHeader direction="row" justifyContent="space-between" alignItems="center">
      <Logo width={78} height={18} />

      {isAuthorized ? (
        <Button
          title="Logout"
          size="medium"
          variant="text"
          disableRipple
          startIcon={<LogOut width={24} height={24} />}
          onClick={handleLogout}
        >
          Log out
        </Button>
      ) : (
        <Box />
      )}
    </StyledHeader>
  );
};

export { Header };
