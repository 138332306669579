import { FC } from 'react';
import { Amplify } from 'aws-amplify';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { CacheProvider } from '@emotion/react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { AuthStatusProvider } from './contexts';
import { theme } from 'src/styles/theme';
import createCache from '@emotion/cache';
import { Main } from 'src/components/templates';
import { AppRoutes } from './common';
import { Login, Summarize, ProtectedRoute, PublicRoute } from 'src/components/organisms';
import { awsConfig } from './configs';

const cache = createCache({
  key: 'css',
  prepend: true,
});

Amplify.configure(awsConfig);

const App: FC = () => {
  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <AuthStatusProvider>
            <Main>
              <Routes>
                <Route
                  path={AppRoutes.HOME}
                  element={
                    <ProtectedRoute>
                      <Summarize />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={AppRoutes.LOGIN}
                  element={
                    <PublicRoute>
                      <Login />
                    </PublicRoute>
                  }
                />
              </Routes>
            </Main>
          </AuthStatusProvider>
        </BrowserRouter>
      </ThemeProvider>
    </CacheProvider>
  );
};

export { App };
