import { AuthStatusContext } from 'src/contexts';
import { useContext } from 'react';

export const useAuthStatus = () => {
  const context = useContext(AuthStatusContext);
  if (!context) {
    throw new Error('useAuthStatus must be used within AuthStatusProvider');
  }

  return context;
};
