import { HttpCode } from 'src/common';

const DEFAULT_MESSAGE = 'Network Error';

class HttpError extends Error {
  status: HttpCode;

  constructor({ status = HttpCode.INTERNAL_SERVER_ERROR, message = DEFAULT_MESSAGE } = {}) {
    super(message);
    this.status = status;
    this.name = 'HttpError';
  }
}

export { HttpError };
