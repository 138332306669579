import { useCallback, useState } from 'react';
import { copyToClipboard } from 'src/helpers';

export const useCopy = (copyText: string) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const handleCopy = useCallback(async () => {
    copyToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch(err => {
        console.error(err);
      });
  }, [copyText]);

  return { isCopied, handleCopy };
};
