import { FC } from 'react';
import { SvgIconProps } from '@mui/material';

import { BaseIcon } from '../base-icon/base-icon';

const CopyDisabled: FC<SvgIconProps> = props => (
  <BaseIcon {...props}>
    <path
      d="M14.5 7V7C14.5 6.06812 14.5 5.60218 14.3478 5.23463C14.1448 4.74458 13.7554 4.35523 13.2654 4.15224C12.8978 4 12.4319 4 11.5 4H8.5C6.61438 4 5.67157 4 5.08579 4.58579C4.5 5.17157 4.5 6.11438 4.5 8V11C4.5 11.9319 4.5 12.3978 4.65224 12.7654C4.85523 13.2554 5.24458 13.6448 5.73463 13.8478C6.10218 14 6.56812 14 7.5 14V14"
      stroke="#A8B6CD"
      strokeWidth="2"
    />
    <rect x="10.5" y="10" width="10" height="10" rx="2" stroke="#A8B6CD" strokeWidth="2" />
  </BaseIcon>
);

export { CopyDisabled };
