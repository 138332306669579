import { styled, Card, Box } from '@mui/material';

interface IPartContainerProps {
  direction: 'left' | 'right';
  paddingTop: number;
}

export const PartContainer = styled(Card, {
  shouldForwardProp: prop => prop !== 'direction' && prop !== 'paddingTop',
})<IPartContainerProps>(({ theme, direction, paddingTop }) => ({
  ...(direction === 'left'
    ? {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: 'initial',
        borderBottomRightRadius: 'initial',
      }
    : {
        borderTopLeftRadius: 'initial',
        borderBottomLeftRadius: 'initial',
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
      }),
  width: 520,
  minHeight: 656,
  padding: theme.spacing(paddingTop, 5, 5, 5),
  background: theme.palette.common.white,
}));

interface IHighlightTextareaWrapperProps {
  type: 'contained' | 'outlined';
  disabled?: boolean;
}

export const HighlightTextareaWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'background' && prop !== 'disabled',
})<IHighlightTextareaWrapperProps>(({ theme, type, disabled = false }) => ({
  position: 'relative',
  '& .DraftEditor-root': {
    background: type === 'contained' ? theme.palette.others.background : theme.palette.common.white,
    border: type === 'outlined' ? `1px solid ${theme.palette.others.placeholderBorder}` : undefined,
    width: '100%',
    minHeight: 502,
    maxHeight: 502,
    overflowY: 'auto',
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    opacity: disabled ? 0.5 : 1,
  },

  '& .DraftEditor-editorContainer': {
    display: 'flex',
    flex: 1,
    cursor: 'text',
    width: '100%',
  },

  '& .public-DraftEditorPlaceholder-inner': {
    color: theme.palette.text.secondary,
  },

  '& .public-DraftEditor-content': {
    minWidth: '100%',
  },

  '& .part-header': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
}));

export const LoadingWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.others.background,
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  width: '100%',
  minHeight: 502,
  maxHeight: 502,
}));
