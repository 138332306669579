import { FC } from 'react';
import { SvgIconProps } from '@mui/material';

import { BaseIcon } from '../base-icon/base-icon';

const Success: FC<SvgIconProps> = props => (
  <BaseIcon {...props}>
    <circle cx="12" cy="12" r="9" stroke="#189A8C" strokeWidth="2" />
    <path d="M8 12L11 15L16 9" stroke="#189A8C" strokeWidth="2" />
  </BaseIcon>
);

export { Success };
