import { FC } from 'react';
import { SvgIconProps } from '@mui/material';

import { BaseIcon } from '../base-icon/base-icon';

const SummarizeWarning: FC<SvgIconProps> = props => (
  <BaseIcon {...props}>
    <path
      d="M75.5398 75.9002C75.5398 79.2002 73.3333 82.2352 70.2342 82.2352H17.039C14.1084 82.2352 11.7333 79.3873 11.7333 75.8778V11.0742C11.7333 9.66705 12.2923 8.31754 13.2873 7.32254C14.2823 6.32754 15.6318 5.76855 17.039 5.76855H54.2658V23.0368C54.2658 23.5373 54.3644 24.0328 54.5559 24.4951C54.7474 24.9575 55.0281 25.3775 55.3819 25.7314C55.7358 26.0853 56.1559 26.3659 56.6182 26.5575C57.0805 26.749 57.5761 26.8475 58.0765 26.8475H75.5398V53.8314C75.5398 53.8314 75.5398 70.4002 75.5398 75.9002Z"
      fill="#F1F2F2"
    />
    <path
      d="M54.2658 5.76855V23.0384C54.2658 23.5387 54.3644 24.0342 54.5559 24.4964C54.7474 24.9587 55.0281 25.3787 55.382 25.7324C55.7359 26.0861 56.156 26.3667 56.6183 26.558C57.0806 26.7494 57.5761 26.8477 58.0765 26.8475H75.5398L54.2658 5.76855Z"
      fill="#F26A69"
    />
    <path
      d="M53.4856 82.2323H17.039C14.1084 82.2323 11.7333 79.3845 11.7333 75.8749V38.0161C15.3908 47.799 20.9692 56.7511 28.1398 64.3448C35.3104 71.9386 43.9282 78.0205 53.4856 82.2323Z"
      fill="#E4E4E8"
    />
    <path
      d="M21.45 31.5335H36.1167C36.3598 31.5335 36.5929 31.4176 36.7648 31.2113C36.9368 31.005 37.0333 30.7252 37.0333 30.4335C37.0333 30.1418 36.9368 29.862 36.7648 29.6557C36.5929 29.4494 36.3598 29.3335 36.1167 29.3335H21.45C21.2069 29.3335 20.9737 29.4494 20.8018 29.6557C20.6299 29.862 20.5333 30.1418 20.5333 30.4335C20.5333 30.7252 20.6299 31.005 20.8018 31.2113C20.9737 31.4176 21.2069 31.5335 21.45 31.5335Z"
      fill="#BABABE"
    />
    <path
      d="M21.45 41.8001H43.45C43.6931 41.8001 43.9263 41.6842 44.0982 41.4779C44.2701 41.2716 44.3667 40.9918 44.3667 40.7001C44.3667 40.4084 44.2701 40.1286 44.0982 39.9223C43.9263 39.716 43.6931 39.6001 43.45 39.6001H21.45C21.2069 39.6001 20.9737 39.716 20.8018 39.9223C20.6299 40.1286 20.5333 40.4084 20.5333 40.7001C20.5333 40.9918 20.6299 41.2716 20.8018 41.4779C20.9737 41.6842 21.2069 41.8001 21.45 41.8001Z"
      fill="#BABABE"
    />
    <path
      d="M21.45 36.6668H28.7833C29.0264 36.6668 29.2596 36.5509 29.4315 36.3446C29.6034 36.1383 29.7 35.8585 29.7 35.5668C29.7 35.2751 29.6034 34.9953 29.4315 34.789C29.2596 34.5827 29.0264 34.4668 28.7833 34.4668H21.45C21.2069 34.4668 20.9737 34.5827 20.8018 34.789C20.6299 34.9953 20.5333 35.2751 20.5333 35.5668C20.5333 35.8585 20.6299 36.1383 20.8018 36.3446C20.9737 36.5509 21.2069 36.6668 21.45 36.6668Z"
      fill="#BABABE"
    />
    <path
      d="M21.45 46.9334H28.7833C29.0264 46.9334 29.2596 46.8175 29.4315 46.6112C29.6034 46.4049 29.7 46.1251 29.7 45.8334C29.7 45.5417 29.6034 45.2619 29.4315 45.0556C29.2596 44.8493 29.0264 44.7334 28.7833 44.7334H21.45C21.2069 44.7334 20.9737 44.8493 20.8018 45.0556C20.6299 45.2619 20.5333 45.5417 20.5333 45.8334C20.5333 46.1251 20.6299 46.4049 20.8018 46.6112C20.9737 46.8175 21.2069 46.9334 21.45 46.9334Z"
      fill="#BABABE"
    />
    <g clipPath="url(#clip0_96_1639)">
      <path
        d="M68.9333 49.1333C77.0332 49.1333 83.6 55.7001 83.6 63.8C83.6 71.8999 77.0332 78.4666 68.9333 78.4666C60.8334 78.4666 54.2667 71.8999 54.2667 63.8C54.2667 55.7001 60.8334 49.1333 68.9333 49.1333Z"
        fill="#E65958"
      />
      <path
        d="M76.7188 72.0173C67.9022 72.0173 60.7545 64.8696 60.7545 56.053C60.7524 54.3847 61.0123 52.7263 61.5245 51.1385C63.771 49.8212 66.3291 49.1288 68.9334 49.1333C77.0333 49.1333 83.6001 55.7001 83.6001 63.8C83.6041 66.4289 82.8978 69.0101 81.5559 71.2708C79.9916 71.7671 78.36 72.0189 76.7188 72.0173Z"
        fill="#F26A69"
      />
      <path
        d="M68.9396 73.7487C69.411 73.7487 69.8718 73.6089 70.2638 73.3471C70.6558 73.0852 70.9614 72.7131 71.1419 72.2776C71.3224 71.8421 71.3697 71.3629 71.2779 70.9005C71.1861 70.4382 70.9593 70.0134 70.6261 69.6799C70.293 69.3464 69.8684 69.1192 69.4061 69.027C68.9438 68.9347 68.4646 68.9816 68.0289 69.1617C67.5933 69.3418 67.2208 69.647 66.9586 70.0387C66.6964 70.4304 66.5562 70.8911 66.5557 71.3625C66.5554 71.6757 66.6169 71.986 66.7365 72.2755C66.8562 72.565 67.0318 72.828 67.2531 73.0496C67.4745 73.2712 67.7374 73.4471 68.0268 73.567C68.3162 73.6869 68.6264 73.7487 68.9396 73.7487ZM66.6291 65.5388C66.6692 65.9072 66.8356 66.2503 67.1 66.5099C67.3679 66.7713 67.7165 66.9343 68.0889 66.9722H68.0997C68.3333 66.9989 68.5682 67.0123 68.8033 67.0123C69.0851 67.0138 69.3668 67.0004 69.6472 66.9722H69.6586C70.0308 66.9341 70.3792 66.7712 70.6469 66.5099C70.9114 66.2503 71.0778 65.9072 71.1179 65.5388C71.1752 65.0008 71.2038 61.2648 71.2038 60.4324C71.2038 59.5999 71.1746 55.8542 71.1179 55.3162C71.0784 54.9468 70.9119 54.6026 70.6469 54.3423C70.3789 54.0813 70.0307 53.9184 69.6586 53.8799H69.6472C69.3668 53.8519 69.0848 53.8433 68.8033 53.8541C68.5687 53.8433 68.3335 53.8519 68.1003 53.8799H68.0889C67.716 53.9195 67.3675 54.0841 67.1 54.3469C66.835 54.6072 66.6685 54.9514 66.6291 55.3208C66.5718 55.8588 66.5431 59.6051 66.5431 60.437C66.5431 61.2688 66.5723 65.0031 66.6291 65.5388Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_96_1639">
        <rect
          width="29.3333"
          height="29.3333"
          fill="white"
          transform="matrix(-1 0 0 1 83.6 49.1333)"
        />
      </clipPath>
    </defs>
  </BaseIcon>
);

export { SummarizeWarning };
