import { FC } from 'react';
import { SvgIconProps } from '@mui/material';

import { BaseIcon } from '../base-icon/base-icon';

const ClearDisabled: FC<SvgIconProps> = props => (
  <BaseIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.33333 13.3333C7.8731 13.3333 7.5 12.9602 7.5 12.4999L7.5 9.99992C7.5 9.53968 7.8731 9.16659 8.33333 9.16659C8.79357 9.16659 9.16667 9.53968 9.16667 9.99992L9.16667 12.4999C9.16667 12.9602 8.79357 13.3333 8.33333 13.3333Z"
      fill="#A8B6CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6667 13.3333C11.2065 13.3333 10.8334 12.9602 10.8334 12.4999L10.8334 9.99992C10.8334 9.53968 11.2065 9.16659 11.6667 9.16659C12.1269 9.16659 12.5 9.53968 12.5 9.99992L12.5 12.4999C12.5 12.9602 12.1269 13.3333 11.6667 13.3333Z"
      fill="#A8B6CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.3552 6.68059C3.15772 6.66712 2.89969 6.66667 2.5 6.66667V5C2.50891 5 2.5178 5 2.52665 5C2.54455 5 2.56232 5 2.57997 5H17.42C17.4377 5 17.4555 5 17.4733 5L17.5 5V6.66667C17.1003 6.66667 16.8423 6.66712 16.6448 6.68059C16.4548 6.69356 16.3824 6.71574 16.3478 6.7301C16.1436 6.81468 15.9813 6.97691 15.8968 7.1811C15.8824 7.21578 15.8602 7.28814 15.8473 7.47813C15.8338 7.67562 15.8333 7.93365 15.8333 8.33333L15.8333 13.3882C15.8334 14.1269 15.8334 14.7634 15.7649 15.2732C15.6914 15.8194 15.5258 16.343 15.1011 16.7678C14.6764 17.1925 14.1528 17.3581 13.6065 17.4316C13.0968 17.5001 12.4603 17.5 11.7215 17.5H8.27847C7.53973 17.5 6.90322 17.5001 6.39351 17.4316C5.84724 17.3581 5.32362 17.1925 4.8989 16.7678C4.47418 16.343 4.30856 15.8194 4.23512 15.2732C4.16659 14.7634 4.16662 14.1269 4.16666 13.3882L4.16667 8.33333C4.16667 7.93365 4.16622 7.67562 4.15274 7.47813C4.13978 7.28814 4.1176 7.21578 4.10323 7.1811C4.01865 6.97691 3.85643 6.81468 3.65224 6.7301C3.61756 6.71574 3.5452 6.69356 3.3552 6.68059ZM14.3107 6.66667H5.68933C5.76749 6.89801 5.79962 7.13137 5.81554 7.36468C5.83335 7.62573 5.83334 7.94114 5.83333 8.30668L5.83333 13.3333C5.83333 14.1426 5.8351 14.6657 5.88692 15.0511C5.93552 15.4125 6.01397 15.5258 6.07741 15.5893C6.14085 15.6527 6.25414 15.7311 6.61559 15.7797C7.00101 15.8316 7.5241 15.8333 8.33333 15.8333H11.6667C12.4759 15.8333 12.999 15.8316 13.3844 15.7797C13.7459 15.7311 13.8592 15.6527 13.9226 15.5893C13.986 15.5258 14.0645 15.4125 14.1131 15.0511C14.1649 14.6657 14.1667 14.1426 14.1667 13.3333V8.30668C14.1667 7.94114 14.1666 7.62573 14.1845 7.36468C14.2004 7.13137 14.2325 6.89801 14.3107 6.66667Z"
      fill="#A8B6CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1716 1.76777C10.8183 1.7 10.4077 1.66675 10 1.66675C9.59232 1.66675 9.18173 1.7 8.82842 1.76777C8.6518 1.80166 8.47888 1.84615 8.32222 1.90477C8.17992 1.95801 7.98585 2.0464 7.82165 2.1996C7.48514 2.51357 7.46687 3.04089 7.78084 3.3774C8.07711 3.69495 8.56336 3.72912 8.89999 3.46814C8.90191 3.4674 8.904 3.4666 8.90626 3.46575C8.95073 3.44911 9.02797 3.42655 9.14242 3.40459C9.37126 3.3607 9.67394 3.33341 10 3.33341C10.3261 3.33341 10.6288 3.3607 10.8576 3.40459C10.9721 3.42655 11.0493 3.44911 11.0938 3.46575C11.096 3.4666 11.0981 3.4674 11.1001 3.46814C11.4367 3.72912 11.9229 3.69495 12.2192 3.3774C12.5332 3.04089 12.5149 2.51357 12.1784 2.1996C12.0142 2.0464 11.8201 1.95801 11.6778 1.90477C11.5212 1.84615 11.3482 1.80166 11.1716 1.76777Z"
      fill="#A8B6CD"
    />
  </BaseIcon>
);

export { ClearDisabled };
