import { useState, useCallback } from 'react';
import { ApiRoutes, HttpCode } from 'src/common';
import { loadJson, loadFormData } from 'src/helpers';

interface IError {
  title: string;
  description: string;
}

enum SummarizingType {
  TEXT = 'text',
  FILE = 'file',
}

interface ISummarizingPayload<TData> {
  type: string;
  data: TData;
}

interface ISignedUrlPayload {
  filename: string;
}

export const useSummarize = () => {
  const [summarizedText, setSummarizedText] = useState<string[]>([]);
  const [updatedOriginalText, setUpdatedOriginalText] = useState<string[]>([]);
  const [textSummarizingLoading, setTextSummarizingLoading] = useState<boolean>(false);
  const [pdfSummarizingLoading, setPdfSummarizingLoading] = useState<boolean>(false);
  const [summarizingError, setSummarizingError] = useState<IError | null>(null);

  const clearSummarizedText = useCallback(() => {
    setSummarizedText([]);
    setSummarizingError(null);
  }, []);

  const summarizeText = useCallback(
    async (text: string) => {
      try {
        clearSummarizedText();
        setTextSummarizingLoading(true);
        const response = await loadJson<ISummarizingPayload<string>>({
          endpoint: ApiRoutes.SUMMARIZE,
          payload: { type: SummarizingType.TEXT, data: text },
        });
        setSummarizedText(response.data.summaries);
        setUpdatedOriginalText(response.data.texts);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error.status === HttpCode.BAD_REQUEST) {
          setSummarizingError({
            title: 'Not enough text to summarize',
            description: 'At least require minimum 1000 words',
          });
        } else {
          setSummarizingError({
            title: 'Oops, something went wrong, please try later',
            description: '',
          });
        }
      } finally {
        setTextSummarizingLoading(false);
      }
    },
    [clearSummarizedText],
  );

  const summarizePdf = useCallback(
    async (file: File) => {
      try {
        clearSummarizedText();
        setPdfSummarizingLoading(true);
        const filename = `${file.name.split('.')[0]}-${file.lastModified}`;
        const {
          data: { fields, url },
        } = await loadJson<ISignedUrlPayload>({
          endpoint: ApiRoutes.SIGNED_URL,
          payload: { filename },
        });
        await loadFormData({
          url,
          payload: { ...fields, file },
        });
        const response = await loadJson<ISummarizingPayload<string>>({
          endpoint: ApiRoutes.SUMMARIZE,
          payload: { type: SummarizingType.FILE, data: filename },
        });
        setSummarizedText(response.data.summaries);
        setUpdatedOriginalText(response.data.texts);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error.status === HttpCode.BAD_REQUEST) {
          setSummarizingError({
            title: "Context is not readable and can't be summarized",
            description: '',
          });
        } else {
          setSummarizingError({
            title: 'Oops, something went wrong, please try later',
            description: '',
          });
        }
      } finally {
        setPdfSummarizingLoading(false);
      }
    },
    [clearSummarizedText],
  );

  return {
    summarizeText,
    summarizePdf,
    clearSummarizedText,
    summarizedText,
    updatedOriginalText,
    textSummarizingLoading,
    pdfSummarizingLoading,
    summarizingError,
  };
};
