import { FC, ReactNode } from 'react';
import { Header } from 'src/components/organisms';
import { StyledContainer, ViewportContainer } from './main.styles';

interface IMainProps {
  children?: ReactNode;
}

const Main: FC<IMainProps> = ({ children }) => {
  return (
    <>
      <Header />
      <ViewportContainer>
        <StyledContainer fixed>{children}</StyledContainer>
      </ViewportContainer>
    </>
  );
};

export { Main };
