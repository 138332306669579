import { FC, ReactNode } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

interface IBaseIconProps extends SvgIconProps {
  children: ReactNode;
  viewBox?: string;
}

const BaseIcon: FC<IBaseIconProps> = ({ width, height, children, viewBox, ...props }) => (
  <SvgIcon
    width={width}
    height={height}
    viewBox={viewBox || `0 0 ${width} ${height}`}
    {...props}
    sx={{
      ...props.sx,
      width: `${width}px`,
      height: `${height}px`,
      color: 'transparent',
    }}
  >
    {children}
  </SvgIcon>
);

export { BaseIcon };
