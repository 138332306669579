import { FC } from 'react';
import { Typography } from '@mui/material';
import { SummarizePlaceholder, SummarizeError } from 'src/components/atoms';
import { PlaceholderContainer, DescriptionList } from './placeholder.styles';

interface IPlaceholderProps {
  type: 'info' | 'error';
  title?: string;
  description?: string;
}

const Placeholder: FC<IPlaceholderProps> = ({ type, title, description }) => {
  return (
    <PlaceholderContainer>
      {type === 'info' ? (
        <>
          <SummarizePlaceholder width={120} height={120} />
          <DescriptionList>
            <li>
              <Typography variant="body1" color="text.secondary" marginLeft={2}>
                Text summarizing can take several minutes.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" color="text.secondary" marginLeft={2}>
                The text must contain a minimum of 1000 words and a maximum of 25000.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" color="text.secondary" marginLeft={2}>
                If the limit is exceeded, the text will be divided into parts, but this may affect
                the quality of the summarized text.
              </Typography>
            </li>
          </DescriptionList>
        </>
      ) : (
        <>
          <SummarizeError width={120} height={120} />
          <Typography variant="h6" color="text.primary" marginTop={10} marginBottom={2}>
            {title}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {description}
          </Typography>
        </>
      )}
    </PlaceholderContainer>
  );
};

export { Placeholder };
