import { ChangeEvent, ReactNode } from 'react';
import { FieldPath, FieldValues, useController } from 'react-hook-form';
import { FormControl } from '@mui/material';
import { ControllerProps } from 'src/common';
import { StyledLabel, StyledInput } from './input.styles';

interface IInputProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> {
  control: ControllerProps<TFieldValues, TName>['control'];
  name: ControllerProps<TFieldValues, TName>['name'];
  id: string;
  label: string;
  placeholder: string;
  type: string;
  endAdornment?: ReactNode;
  marginBottom?: number;
}

const Input = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  id,
  name,
  control,
  label,
  placeholder,
  type,
  endAdornment,
  marginBottom,
}: IInputProps<TFieldValues, TName>) => {
  const { field } = useController({
    name,
    control,
    rules: {
      required: true,
    },
  });

  const handleChange = (value: ChangeEvent<HTMLInputElement>) => {
    field.onChange(value);
  };

  return (
    <FormControl variant="standard" sx={{ marginBottom: marginBottom ?? 6.5 }} fullWidth>
      <StyledLabel shrink htmlFor={id}>
        {label}
      </StyledLabel>
      <StyledInput
        id={id}
        autoComplete="off"
        type={type}
        onChange={handleChange}
        placeholder={placeholder}
        endAdornment={endAdornment}
      />
    </FormControl>
  );
};

export { Input };
