import { FC } from 'react';
import { Dialog, DialogContent, DialogActions, Typography, Button } from '@mui/material';
import { SummarizeWarning, Close } from 'src/components/atoms';
import { StyledCloseButton } from './confirmation-dialog.styles';

interface IConfirmationDialogProps {
  isOpened: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmationDialog: FC<IConfirmationDialogProps> = ({ isOpened, onClose, onConfirm }) => {
  return (
    <Dialog onClose={onClose} open={isOpened} PaperProps={{ sx: { maxWidth: 476 } }}>
      <StyledCloseButton onClick={onClose}>
        <Close width={24} height={24} />
      </StyledCloseButton>
      <DialogContent>
        <SummarizeWarning width={88} height={88} />
        <Typography variant="h6" color="text.primary" marginTop={6} marginBottom={2} align="center">
          You have exceeded the word limit
        </Typography>
        <Typography variant="body1" color="text.secondary" align="center">
          We will divide your text into parts, but this may affect the quality of the summarized
          text.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={onClose}
          size="large"
          autoFocus
          sx={{ marginRight: 5 }}
        >
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={onConfirm} size="large" autoFocus>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { ConfirmationDialog };
