import { FC } from 'react';
import { SvgIconProps } from '@mui/material';

import { BaseIcon } from '../base-icon/base-icon';

const SummarizeError: FC<SvgIconProps> = props => (
  <BaseIcon {...props}>
    <path
      d="M79.1378 75.1915C78.4841 75.8438 77.9654 76.6186 77.6116 77.4715C77.2577 78.3245 77.0756 79.2389 77.0756 80.1623C77.0756 81.0858 77.2577 82.0002 77.6116 82.8531C77.9654 83.7061 78.4841 84.4809 79.1378 85.1332L84.5959 90.5892L79.1378 96.0473C77.8194 97.3657 77.0788 99.1537 77.0788 101.018C77.0788 102.883 77.8194 104.671 79.1378 105.989C80.4561 107.307 82.2442 108.048 84.1086 108.048C85.973 108.048 87.7611 107.307 89.0794 105.989L94.5376 100.531L99.9936 105.989C100.623 106.622 101.368 107.128 102.19 107.479C100.983 110.245 98.5615 112.139 95.7739 112.139H23.235C19.2387 112.139 16 108.255 16 103.47V15.1009C16 13.1821 16.7623 11.3419 18.1191 9.98504C19.4759 8.62822 21.3161 7.86597 23.235 7.86597H73.9988V31.4136C73.9988 32.096 74.1332 32.7717 74.3944 33.4022C74.6555 34.0327 75.0383 34.6055 75.5208 35.088C76.0033 35.5706 76.5762 35.9533 77.2066 36.2145C77.8371 36.4756 78.5128 36.61 79.1952 36.61H103.009V73.4062C101.869 73.7354 100.831 74.349 99.9936 75.1894L94.5376 80.6411L89.0794 75.1851C88.4268 74.5318 87.6516 74.0137 86.7984 73.6604C85.9452 73.3071 85.0307 73.1255 84.1073 73.1261C83.1838 73.1267 82.2695 73.3094 81.4168 73.6638C80.5641 74.0183 79.7896 74.5374 79.1378 75.1915Z"
      fill="#F1F2F2"
    />
    <path
      d="M73.9988 7.86597V31.4157C73.9988 32.098 74.1332 32.7737 74.3944 33.404C74.6555 34.0343 75.0383 34.607 75.5209 35.0894C76.0035 35.5718 76.5763 35.9543 77.2068 36.2152C77.8372 36.4762 78.5129 36.6103 79.1952 36.61H103.009L73.9988 7.86597Z"
      fill="#F26A69"
    />
    <path
      d="M99.9936 75.1915L94.5375 80.6411L89.0794 75.183C87.7611 73.8646 85.973 73.124 84.1086 73.124C82.2441 73.124 80.4561 73.8646 79.1377 75.183C77.8194 76.5013 77.0787 78.2894 77.0787 80.1538C77.0787 82.0182 77.8194 83.8063 79.1377 85.1246L84.5959 90.5828L79.1377 96.0473C78.484 96.6996 77.9654 97.4744 77.6116 98.3273C77.2577 99.1803 77.0756 100.095 77.0756 101.018C77.0756 101.942 77.2577 102.856 77.6116 103.709C77.9654 104.562 78.484 105.337 79.1377 105.989C79.79 106.643 80.5648 107.161 81.4178 107.515C82.2707 107.869 83.1851 108.051 84.1086 108.051C85.032 108.051 85.9464 107.869 86.7994 107.515C87.6524 107.161 88.4271 106.643 89.0794 105.989L94.5375 100.533L99.9936 105.989C101.312 107.307 103.1 108.048 104.964 108.048C106.829 108.048 108.617 107.307 109.935 105.989C111.254 104.671 111.994 102.883 111.994 101.018C111.994 99.1537 111.254 97.3656 109.935 96.0473L104.479 90.5913L109.935 85.1331C110.589 84.4809 111.108 83.7061 111.461 82.8531C111.815 82.0001 111.997 81.0858 111.997 80.1623C111.997 79.2388 111.815 78.3245 111.461 77.4715C111.108 76.6185 110.589 75.8437 109.935 75.1915C109.283 74.5378 108.508 74.0191 107.655 73.6653C106.802 73.3114 105.888 73.1293 104.964 73.1293C104.041 73.1293 103.127 73.3114 102.274 73.6653C101.421 74.0191 100.646 74.5378 99.9936 75.1915Z"
      fill="#F26A69"
    />
    <path
      d="M72.9348 112.134H23.235C19.2387 112.134 16 108.251 16 103.465V51.8396C20.9875 65.1799 28.5943 77.3873 38.3724 87.7424C48.1505 98.0975 59.9021 106.391 72.9348 112.134Z"
      fill="#E4E4E8"
    />
    <path
      d="M29.25 43H49.25C49.5815 43 49.8995 42.842 50.1339 42.5607C50.3683 42.2794 50.5 41.8978 50.5 41.5C50.5 41.1022 50.3683 40.7206 50.1339 40.4393C49.8995 40.158 49.5815 40 49.25 40H29.25C28.9185 40 28.6005 40.158 28.3661 40.4393C28.1317 40.7206 28 41.1022 28 41.5C28 41.8978 28.1317 42.2794 28.3661 42.5607C28.6005 42.842 28.9185 43 29.25 43Z"
      fill="#BABABE"
    />
    <path
      d="M29.25 57H59.25C59.5815 57 59.8995 56.842 60.1339 56.5607C60.3683 56.2794 60.5 55.8978 60.5 55.5C60.5 55.1022 60.3683 54.7206 60.1339 54.4393C59.8995 54.158 59.5815 54 59.25 54H29.25C28.9185 54 28.6005 54.158 28.3661 54.4393C28.1317 54.7206 28 55.1022 28 55.5C28 55.8978 28.1317 56.2794 28.3661 56.5607C28.6005 56.842 28.9185 57 29.25 57Z"
      fill="#BABABE"
    />
    <path
      d="M29.25 50H39.25C39.5815 50 39.8995 49.842 40.1339 49.5607C40.3683 49.2794 40.5 48.8978 40.5 48.5C40.5 48.1022 40.3683 47.7206 40.1339 47.4393C39.8995 47.158 39.5815 47 39.25 47H29.25C28.9185 47 28.6005 47.158 28.3661 47.4393C28.1317 47.7206 28 48.1022 28 48.5C28 48.8978 28.1317 49.2794 28.3661 49.5607C28.6005 49.842 28.9185 50 29.25 50Z"
      fill="#BABABE"
    />
    <path
      d="M29.25 64H39.25C39.5815 64 39.8995 63.842 40.1339 63.5607C40.3683 63.2794 40.5 62.8978 40.5 62.5C40.5 62.1022 40.3683 61.7206 40.1339 61.4393C39.8995 61.158 39.5815 61 39.25 61H29.25C28.9185 61 28.6005 61.158 28.3661 61.4393C28.1317 61.7206 28 62.1022 28 62.5C28 62.8978 28.1317 63.2794 28.3661 63.5607C28.6005 63.842 28.9185 64 29.25 64Z"
      fill="#BABABE"
    />
  </BaseIcon>
);

export { SummarizeError };
