import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { useCopy, useSummarize } from 'src/hooks';
import { Clear, Copy, Success, CopyDisabled, ClearDisabled } from 'src/components/atoms';
import { Placeholder, ConfirmationDialog, UploadPdf } from 'src/components/molecules';
import { HighlightWithinTextarea } from 'react-highlight-within-textarea';
import { PartContainer, HighlightTextareaWrapper, LoadingWrapper } from './summarize.styles';
import { getNumberOfWords, joinSummarizedText } from 'src/helpers';

const MAX_WORD_LIMIT = 25000;
const PART_HEADER_REGEX = /Part[1-9]+/gim;

const Summarize: FC = () => {
  const [isTextareaFocused, setIsTextareaFocused] = useState<boolean>(false);
  const [originalText, setOriginalText] = useState<string>('');
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

  const {
    summarizeText,
    summarizePdf,
    clearSummarizedText,
    updatedOriginalText,
    summarizedText,
    textSummarizingLoading,
    pdfSummarizingLoading,
    summarizingError,
  } = useSummarize();
  const { isCopied, handleCopy } = useCopy(joinSummarizedText(summarizedText));
  const numberOfOriginalTextWords = useMemo(() => getNumberOfWords(originalText), [originalText]);
  const numberOfSummarizedTextWords = useMemo(
    () => getNumberOfWords(joinSummarizedText(summarizedText)),
    [summarizedText],
  );

  useEffect(() => {
    if (updatedOriginalText.length === 1) {
      setOriginalText(updatedOriginalText[0]);

      return;
    }

    setOriginalText(joinSummarizedText(updatedOriginalText));
  }, [updatedOriginalText, updatedOriginalText.length]);

  const handleChange = (value: string) => {
    setOriginalText(value);
  };

  const handleCloseConfirmationModal = () => {
    setIsModalOpened(false);
  };

  const handleSummarizeClick = () => {
    if (numberOfOriginalTextWords > MAX_WORD_LIMIT) {
      setIsModalOpened(true);

      return;
    }

    handleSummarizeText();
  };

  const handleSummarizeText = () => {
    summarizeText(originalText);
    handleCloseConfirmationModal();
  };

  const handleSummarizePdf = (pdfFile: File | null) => {
    if (pdfFile) {
      summarizePdf(pdfFile);
    }
  };

  const handleTextareaFocus = () => {
    setIsTextareaFocused(true);
  };

  const handleTextareaBlur = () => {
    setIsTextareaFocused(false);
  };

  const handleClearOriginalText = () => {
    setOriginalText('');
    clearSummarizedText();
  };

  return (
    <>
      <Stack direction="row">
        <PartContainer direction="left" paddingTop={5} elevation={0}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            marginBottom={4}
          >
            <Typography variant="h4">Original text</Typography>
            <Stack direction="row" alignItems="center">
              <Typography variant="body2" color="text.secondary" marginRight={4}>
                {numberOfOriginalTextWords}/{MAX_WORD_LIMIT} words
              </Typography>
              <Button
                variant="contained"
                size="small"
                disabled={!originalText.length || textSummarizingLoading}
                onClick={handleClearOriginalText}
                startIcon={
                  !originalText.length || textSummarizingLoading ? (
                    <ClearDisabled width={20} height={20} />
                  ) : (
                    <Clear width={20} height={20} />
                  )
                }
              >
                Clear
              </Button>
            </Stack>
          </Stack>
          {pdfSummarizingLoading ? (
            <LoadingWrapper>
              <CircularProgress />
            </LoadingWrapper>
          ) : (
            <HighlightTextareaWrapper type="contained" disabled={textSummarizingLoading}>
              <HighlightWithinTextarea
                readOnly={textSummarizingLoading}
                value={originalText}
                onChange={handleChange}
                onFocus={handleTextareaFocus}
                onBlur={handleTextareaBlur}
                placeholder="Write about your topic..."
                highlight={[
                  {
                    highlight: PART_HEADER_REGEX,
                    className: 'part-header',
                  },
                ]}
              />
              {!originalText.length && (
                <UploadPdf
                  onFileUpload={handleSummarizePdf}
                  isTextareaFocused={isTextareaFocused}
                />
              )}
            </HighlightTextareaWrapper>
          )}

          <Stack direction="row" justifyContent="space-between" alignItems="center" marginTop={5}>
            {textSummarizingLoading ? (
              <Stack direction="row" alignItems="center">
                <Typography fontSize={22} marginRight={3}>
                  ☝️
                </Typography>
                <Typography variant="body1" color="text.primary" maxWidth={251}>
                  Text summarizing can take several minutes. Please, be patient...
                </Typography>
              </Stack>
            ) : (
              <Box />
            )}

            <Button
              variant="contained"
              size="large"
              onClick={handleSummarizeClick}
              disabled={!originalText.length || textSummarizingLoading}
            >
              {textSummarizingLoading ? (
                <CircularProgress color="secondary" size={20} />
              ) : (
                'Summarize'
              )}
            </Button>
          </Stack>
        </PartContainer>
        <PartContainer direction="right" paddingTop={6} elevation={0}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            marginBottom={5}
          >
            <Typography variant="h4">Summarized text</Typography>
            <Stack direction="row" alignItems="center">
              <Typography variant="body2" color="text.secondary">
                {numberOfSummarizedTextWords} words
              </Typography>
            </Stack>
          </Stack>
          {summarizedText.length ? (
            <HighlightTextareaWrapper type="outlined">
              <HighlightWithinTextarea
                value={joinSummarizedText(summarizedText)}
                highlight={[
                  {
                    highlight: PART_HEADER_REGEX,
                    className: 'part-header',
                  },
                ]}
                placeholder=""
                readOnly
              />
            </HighlightTextareaWrapper>
          ) : summarizingError ? (
            <Placeholder
              type="error"
              title={summarizingError.title}
              description={summarizingError.description}
            />
          ) : (
            <Placeholder type="info" />
          )}

          <Stack direction="row-reverse" alignItems="center" marginTop={5}>
            {isCopied ? (
              <Button
                variant="contained"
                color="success"
                size="large"
                startIcon={<Success width={24} height={24} />}
                disableRipple
              >
                Copied
              </Button>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCopy}
                size="large"
                disabled={!summarizedText.length}
                startIcon={
                  !summarizedText.length ? (
                    <CopyDisabled width={25} height={24} />
                  ) : (
                    <Copy width={25} height={24} />
                  )
                }
              >
                Copy text
              </Button>
            )}
          </Stack>
        </PartContainer>
      </Stack>
      <ConfirmationDialog
        isOpened={isModalOpened}
        onClose={handleCloseConfirmationModal}
        onConfirm={handleSummarizeText}
      />
    </>
  );
};

export { Summarize };
