import { ChangeEvent, FC, useRef } from 'react';
import { Typography, Button } from '@mui/material';
import { PdfFile, Upload } from 'src/components/atoms';
import { UploaderContainer } from './upload-pdf.styles';

interface IUploadPdfProps {
  isTextareaFocused: boolean;
  onFileUpload: (file: File | null) => void;
}

const UploadPdf: FC<IUploadPdfProps> = ({ isTextareaFocused, onFileUpload }) => {
  const uploadInputRef = useRef<HTMLInputElement>(null);

  const handleUploadClick = () => {
    if (uploadInputRef.current) {
      uploadInputRef.current.click();
    }
  };

  const handleFileChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    onFileUpload(target.files?.[0] ?? null);
  };

  return (
    <UploaderContainer isTextareaFocused={isTextareaFocused}>
      <PdfFile width={64} height={64} />
      <Typography variant="body1" color="text.secondary" marginTop={5} marginBottom={5}>
        Or upload PDF file
      </Typography>
      <Button
        variant="contained"
        component="label"
        color="primary"
        onClick={handleUploadClick}
        size="medium"
        startIcon={<Upload width={25} height={24} />}
      >
        Upload PDF
      </Button>
      <input
        type="file"
        accept="application/pdf"
        ref={uploadInputRef}
        onChange={handleFileChange}
        hidden
      />
    </UploaderContainer>
  );
};

export { UploadPdf };
