import { TypographyOptions, TypographyStyleOptions } from '@mui/material/styles/createTypography';

const h4: TypographyStyleOptions = {
  fontWeight: 500,
  fontSize: 18,
  lineHeight: '22.5px',
  fontFamily: "'Lexend', sans-serif",
};

const h5: TypographyStyleOptions = {
  fontWeight: 500,
  fontSize: 18,
  lineHeight: '22.5px',
  fontFamily: "'Inter', sans-serif",
};

const h6: TypographyStyleOptions = {
  fontWeight: 500,
  fontSize: 16,
  lineHeight: '24px',
  fontFamily: "'Lexend', sans-serif",
};

const body1: TypographyStyleOptions = {
  fontWeight: 400,
  fontSize: 14,
  lineHeight: '20px',
  fontFamily: "'Inter', sans-serif",
};

const body2: TypographyStyleOptions = {
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '20px',
  fontFamily: "'Inter', sans-serif",
};

const subtitle1: TypographyStyleOptions = {
  fontWeight: 400,
  fontSize: 14,
  lineHeight: '17px',
};

const subtitle2: TypographyStyleOptions = {
  fontWeight: 400,
  fontSize: 12,
  lineHeight: '18px',
};

const button: TypographyStyleOptions = {
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '22px',
  textTransform: undefined,
  fontFamily: "'Inter', sans-serif",
};

export const fontSizes = {
  h4,
  h5,
  h6,
  body1,
  body2,
  subtitle1,
  subtitle2,
  button,
};

export const typography: TypographyOptions = {
  ...fontSizes,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  fontSize: 16,
};
