import { useCallback, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'src/common';

export interface ILoginPayload {
  email: string;
  password: string;
}

export const useAmplify = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [hasLoginError, setHasLoginError] = useState<boolean>(false);

  const handleLogin = useCallback(async (payload: ILoginPayload) => {
    try {
      setLoading(true);
      await Auth.signIn(payload.email, payload.password);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.code === 'NotAuthorizedException') {
        setHasLoginError(true);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  const handleLogout = useCallback(async () => {
    await Auth.signOut();
    navigate(AppRoutes.LOGIN);
  }, [navigate]);

  return { handleLogin, handleLogout, hasLoginError, loading };
};
