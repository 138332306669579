import { FC } from 'react';
import { SvgIconProps } from '@mui/material';

import { BaseIcon } from '../base-icon/base-icon';

const Upload: FC<SvgIconProps> = props => (
  <BaseIcon {...props}>
    <path
      d="M12.5 5L11.7929 4.29289L12.5 3.58579L13.2071 4.29289L12.5 5ZM13.5 14C13.5 14.5523 13.0523 15 12.5 15C11.9477 15 11.5 14.5523 11.5 14L13.5 14ZM6.79289 9.29289L11.7929 4.29289L13.2071 5.70711L8.20711 10.7071L6.79289 9.29289ZM13.2071 4.29289L18.2071 9.29289L16.7929 10.7071L11.7929 5.70711L13.2071 4.29289ZM13.5 5L13.5 14L11.5 14L11.5 5L13.5 5Z"
      fill="white"
    />
    <path
      d="M5.5 16L5.5 17C5.5 18.1046 6.39543 19 7.5 19L17.5 19C18.6046 19 19.5 18.1046 19.5 17V16"
      stroke="white"
      strokeWidth="2"
    />
  </BaseIcon>
);

export { Upload };
