import { createContext } from 'react';

interface IAuthStatusContext {
  isAuthorized: boolean;
  isInitialChecking: boolean;
}

export const AuthStatusContext = createContext<IAuthStatusContext>({
  isAuthorized: false,
  isInitialChecking: false,
});
