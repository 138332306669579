import { FC } from 'react';
import { SvgIconProps } from '@mui/material';

import { BaseIcon } from '../base-icon/base-icon';

const LogOut: FC<SvgIconProps> = props => (
  <BaseIcon {...props}>
    <path
      d="M2 12L1.21913 11.3753L0.719375 12L1.21913 12.6247L2 12ZM11 13C11.5523 13 12 12.5523 12 12C12 11.4477 11.5523 11 11 11V13ZM5.21913 6.3753L1.21913 11.3753L2.78087 12.6247L6.78087 7.6247L5.21913 6.3753ZM1.21913 12.6247L5.21913 17.6247L6.78087 16.3753L2.78087 11.3753L1.21913 12.6247ZM2 13H11V11H2V13Z"
      fill="#343553"
    />
    <path
      d="M10 8.13193V8C10 6.11438 10 5.17157 10.5858 4.58579C11.1716 4 12.1144 4 14 4H16C18.8284 4 20.2426 4 21.1213 4.87868C22 5.75736 22 7.17157 22 10V14C22 16.8284 22 18.2426 21.1213 19.1213C20.2426 20 18.8284 20 16 20H13.934C12.1125 20 11.2018 20 10.6229 19.4504C10.5978 19.4266 10.5734 19.4022 10.5496 19.3771C10 18.7982 10 17.8875 10 16.066V16.066"
      stroke="#343553"
      strokeWidth="2"
    />
  </BaseIcon>
);

export { LogOut };
