import { PaletteOptions } from '@mui/material';

export const palette: PaletteOptions = {
  common: {
    white: '#FFFFFF',
    black: '#000000',
  },
  primary: {
    main: '#1669FF',
  },
  secondary: {
    main: '#EAF2FF',
  },
  text: {
    primary: '#343553',
    secondary: '#6C7B8F',
    disabled: '#6C7B8F',
  },
  success: {
    main: '#E5FBF0',
    contrastText: '#189A8C',
  },
  action: {
    disabled: '#A8B6CD',
  },
  primaryStates: {
    hover: '#327AFC',
    disabled: '#A8B6CD',
    disabledSecondary: '#F5F9F7',
    pressed: '#1669FF',
  },
  others: {
    background: '#F5F7F9',
    headerBorder: '#D5D8E6',
    error: '#D52836',
    placeholderBorder: '#EDF0F5',
    uploadPdfBorder: '#DAE4EF',
  },
};
