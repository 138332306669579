import { Box, styled } from '@mui/material';

interface IUploaderContainerProps {
  isTextareaFocused: boolean;
}

export const UploaderContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'isTextareaFocused',
})<IUploaderContainerProps>(({ theme, isTextareaFocused }) => ({
  border: `1px solid ${theme.palette.others.uploadPdfBorder}`,
  background: theme.palette.others.placeholderBorder,
  borderRadius: 16,
  width: 298,
  padding: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 137,
  left: 91,
  opacity: isTextareaFocused ? 0.5 : 1,
  '&:hover': {
    opacity: 1,
  },
}));
