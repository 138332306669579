import { FC, ReactNode, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthStatusContext } from 'src/contexts';
import { AppRoutes } from 'src/common';

interface IPublicRouteProps {
  children: ReactNode;
}

const PublicRoute: FC<IPublicRouteProps> = ({ children }) => {
  const { isAuthorized, isInitialChecking } = useContext(AuthStatusContext);

  if (isAuthorized || isInitialChecking) {
    return <Navigate to={AppRoutes.HOME} />;
  }

  return <>{children}</>;
};

export { PublicRoute };
