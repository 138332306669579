import { Auth } from 'aws-amplify';
import { HttpHeader, HttpMethod, ContentType } from 'src/common';
import { HttpError } from 'src/exceptions';

interface IHttpOptions<TPayload> {
  endpoint: string;
  method: HttpMethod;
  contentType: ContentType;
  payload: TPayload;
  apiUrl: string;
  redirect: RequestRedirect;
  mode: RequestMode;
}

export const loadJson = <TPayload>({
  endpoint,
  payload,
}: {
  endpoint: string;
  payload: TPayload;
}) => {
  return load<string>({
    endpoint,
    contentType: ContentType.JSON,
    payload: JSON.stringify(payload),
  });
};

export const loadFormData = ({
  url,
  payload,
}: {
  url: string;
  payload: Record<string, string | File>;
}) => {
  const formData = new FormData();
  Object.keys(payload).forEach(key => {
    if (key === 'file') {
      formData.append(key, payload[key], (payload[key] as File).name);
    } else {
      formData.append(key, payload[key]);
    }
  });

  return load<FormData>({
    apiUrl: url,
    payload: formData,
    redirect: 'follow',
    mode: 'no-cors',
  });
};

const load = async <TPayload extends BodyInit>(options: Partial<IHttpOptions<TPayload>>) => {
  const {
    method = HttpMethod.POST,
    payload = null,
    contentType,
    endpoint = '',
    apiUrl = process.env.REACT_APP_API_URL,
    redirect,
    mode,
  } = options;
  const headers = await getHeaders(contentType);

  return fetch(`${apiUrl}${endpoint}`, {
    method,
    headers,
    body: payload,
    redirect,
    mode,
  })
    .then(checkStatus)
    .then(response => (response.type === 'opaque' ? response.text() : response.json()));
};

const getHeaders = async (contentType?: ContentType): Promise<Headers> => {
  const headers = new Headers();

  if (contentType) {
    headers.append(HttpHeader.CONTENT_TYPE, contentType);
  }

  if (contentType === ContentType.JSON) {
    const session = await Auth.currentSession();
    headers.append(HttpHeader.AUTHORIZATION, `Bearer ${session.getAccessToken().getJwtToken()}`);
  }

  return headers;
};

const checkStatus = async (response: Response): Promise<Response> => {
  if (response.type === 'opaque') {
    return response;
  }

  if (!response.ok) {
    const parsedException = await response.json().catch(() => ({
      message: response.statusText,
    }));

    throw new HttpError({
      status: response.status,
      message: parsedException?.message,
    });
  }

  return response;
};
