import { CSSProperties } from 'react';
import { createTheme } from '@mui/material';
import { palette } from './palette';
import { shape } from './shape';
import { typography } from './typography';
import { components } from './components';

declare module '@mui/material/styles' {
  interface Palette {
    primaryStates: {
      hover: CSSProperties['color'];
      disabled: CSSProperties['color'];
      disabledSecondary: CSSProperties['color'];
      pressed: CSSProperties['color'];
    };
    others: {
      background: CSSProperties['color'];
      headerBorder: CSSProperties['color'];
      error: CSSProperties['color'];
      placeholderBorder: CSSProperties['color'];
      uploadPdfBorder: CSSProperties['color'];
    };
  }
  interface PaletteOptions {
    primaryStates: {
      hover: CSSProperties['color'];
      disabled: CSSProperties['color'];
      disabledSecondary: CSSProperties['color'];
      pressed: CSSProperties['color'];
    };
    others: {
      background: CSSProperties['color'];
      headerBorder: CSSProperties['color'];
      error: CSSProperties['color'];
      placeholderBorder: CSSProperties['color'];
      uploadPdfBorder: CSSProperties['color'];
    };
  }

  interface PaletteColorOptions {
    main: CSSProperties['color'];
    light?: CSSProperties['color'];
    dark?: CSSProperties['color'];
    contrastText?: CSSProperties['color'];
    secondary?: CSSProperties['color'];
  }
}

export const theme = createTheme({
  components,
  palette,
  shape,
  typography,
  spacing: 4,
});
