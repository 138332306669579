import { FC } from 'react';
import { SvgIconProps } from '@mui/material';

import { BaseIcon } from '../base-icon/base-icon';

const SummarizePlaceholder: FC<SvgIconProps> = props => (
  <BaseIcon {...props}>
    <path
      d="M19.824 96.035C15.715 95.4478 11.9177 93.5118 9.0288 90.5314C6.13992 87.551 4.32328 83.6952 3.86439 79.5699C3.4055 75.4446 4.33038 71.2838 6.49367 67.7415C8.65696 64.1991 11.936 61.4759 15.8155 60C11.9359 58.5239 8.65677 55.8006 6.49343 52.258C4.33009 48.7154 3.40519 44.5544 3.86407 40.429C4.32295 36.3035 6.1396 32.4475 9.02852 29.4669C11.9174 26.4863 15.7149 24.55 19.824 23.9625L20.174 26.438C16.3087 26.9918 12.7728 28.9208 10.215 31.8712C7.65717 34.8215 6.24909 38.5953 6.24909 42.5C6.24909 46.4048 7.65717 50.1785 10.215 53.1289C12.7728 56.0792 16.3087 58.0083 20.174 58.562C20.4724 58.604 20.7457 58.7524 20.9434 58.9799C21.1411 59.2074 21.25 59.4986 21.25 59.8V60.2C21.2501 60.5011 21.1415 60.7922 20.9442 61.0196C20.7468 61.2471 20.4741 61.3956 20.176 61.438C16.3107 61.9922 12.7748 63.9215 10.2171 66.8721C7.65945 69.8226 6.25144 73.5965 6.25144 77.5013C6.25144 81.4061 7.65945 85.1799 10.2171 88.1305C12.7748 91.081 16.3107 93.0104 20.176 93.5645L19.824 96.035Z"
      fill="#B5C1D4"
    />
    <path
      d="M20.5785 29.182C20.8364 29.2884 21.1126 29.3432 21.3915 29.3435C21.7713 29.3267 22.1436 29.233 22.4861 29.068C22.8286 28.9031 23.1341 28.6704 23.384 28.384L25.884 25.884C26.0001 25.7679 26.0922 25.6301 26.1551 25.4784C26.2179 25.3268 26.2502 25.1642 26.2502 25C26.2502 24.8358 26.2179 24.6733 26.1551 24.5216C26.0922 24.3699 26.0001 24.2321 25.884 24.116L23.384 21.616C23.0629 21.2008 22.6174 20.8992 22.1126 20.7553C21.6079 20.6113 21.0703 20.6326 20.5785 20.816C19.4 21.306 18.75 22.7905 18.75 25C18.75 27.2095 19.4 28.694 20.5785 29.182Z"
      fill="#E7EFF7"
    />
    <path
      d="M23.384 56.616C23.0624 56.2014 22.6169 55.9002 22.1123 55.7563C21.6077 55.6124 21.0704 55.6333 20.5785 55.816C19.4 56.306 18.75 57.7905 18.75 60C18.75 62.2095 19.4 63.694 20.5785 64.182C20.8364 64.2883 21.1126 64.3432 21.3915 64.3435C21.7713 64.3267 22.1436 64.233 22.4861 64.068C22.8286 63.9031 23.1341 63.6704 23.384 63.384L25.884 60.884C26.0001 60.7679 26.0922 60.6301 26.1551 60.4784C26.2179 60.3267 26.2502 60.1642 26.2502 60C26.2502 59.8358 26.2179 59.6732 26.1551 59.5216C26.0922 59.3699 26.0001 59.2321 25.884 59.116L23.384 56.616Z"
      fill="#E7EFF7"
    />
    <path
      d="M23.384 91.616C23.0614 91.2026 22.6159 90.9024 22.1117 90.7586C21.6074 90.6148 21.0706 90.6349 20.5785 90.816C19.4 91.3035 18.75 92.793 18.75 95C18.75 97.207 19.4 98.6965 20.5785 99.1845C20.8363 99.2909 21.1126 99.3456 21.3915 99.3455C21.7712 99.3277 22.1435 99.2333 22.4858 99.0681C22.8282 98.9028 23.1337 98.6702 23.384 98.384L25.884 95.884C26.0001 95.7679 26.0922 95.6301 26.1551 95.4785C26.2179 95.3268 26.2502 95.1642 26.2502 95C26.2502 94.8358 26.2179 94.6733 26.1551 94.5216C26.0922 94.3699 26.0001 94.2321 25.884 94.116L23.384 91.616Z"
      fill="#E7EFF7"
    />
    <path
      d="M40 41.25H100C104.31 41.25 108.443 39.538 111.49 36.4905C114.538 33.443 116.25 29.3098 116.25 25C116.25 20.6902 114.538 16.557 111.49 13.5095C108.443 10.462 104.31 8.75 100 8.75H40C38.3428 8.75146 36.754 9.4104 35.5822 10.5822C34.4104 11.754 33.7515 13.3428 33.75 15V15.4055C33.7978 16.7185 34.147 18.0029 34.7706 19.1594C35.3941 20.3159 36.2753 21.3135 37.346 22.075C37.784 22.4264 38.1375 22.8716 38.3803 23.3779C38.6232 23.8841 38.7493 24.4385 38.7493 25C38.7493 25.5615 38.6232 26.1159 38.3803 26.6221C38.1375 27.1284 37.784 27.5736 37.346 27.925C36.2751 28.6863 35.3938 29.6839 34.7703 30.8404C34.1467 31.9969 33.7976 33.2814 33.75 34.5945V35C33.7515 36.6572 34.4104 38.246 35.5822 39.4178C36.754 40.5896 38.3428 41.2485 40 41.25Z"
      fill="#E7EFF7"
    />
    <path
      d="M58.75 41.25H40C38.3424 41.25 36.7527 40.5915 35.5806 39.4194C34.4085 38.2473 33.75 36.6576 33.75 35V34.6C33.7921 33.288 34.1393 32.004 34.7642 30.8496C35.3891 29.6953 36.2745 28.7025 37.35 27.95C37.7882 27.5935 38.1413 27.1437 38.3836 26.6333C38.6258 26.1229 38.751 25.5649 38.75 25C38.7559 24.4414 38.6327 23.889 38.3898 23.386C38.1469 22.8829 37.7911 22.4428 37.35 22.1C36.2768 21.3336 35.3941 20.3308 34.7699 19.1692C34.1457 18.0075 33.7968 16.7179 33.75 15.4V15C33.75 13.3424 34.4085 11.7527 35.5806 10.5806C36.7527 9.40848 38.3424 8.75 40 8.75H58.75C57.0924 8.75 55.5027 9.40848 54.3306 10.5806C53.1585 11.7527 52.5 13.3424 52.5 15V15.4C52.5468 16.7179 52.8957 18.0075 53.5199 19.1692C54.1441 20.3308 55.0268 21.3336 56.1 22.1C56.5411 22.4428 56.8969 22.8829 57.1398 23.386C57.3827 23.889 57.5059 24.4414 57.5 25C57.501 25.5649 57.3758 26.1229 57.1336 26.6333C56.8913 27.1437 56.5382 27.5935 56.1 27.95C55.0245 28.7025 54.1391 29.6953 53.5142 30.8496C52.8893 32.004 52.5421 33.288 52.5 34.6V35C52.5 36.6576 53.1585 38.2473 54.3306 39.4194C55.5027 40.5915 57.0924 41.25 58.75 41.25Z"
      fill="#DAE4EF"
    />
    <path
      d="M100 43.75H40C38.3428 43.7515 36.754 44.4104 35.5822 45.5822C34.4104 46.754 33.7515 48.3428 33.75 50V50.4055C33.7978 51.7185 34.147 53.0029 34.7706 54.1594C35.3941 55.3159 36.2753 56.3135 37.346 57.075C37.784 57.4264 38.1375 57.8716 38.3803 58.3779C38.6232 58.8841 38.7493 59.4385 38.7493 60C38.7493 60.5615 38.6232 61.1159 38.3803 61.6221C38.1375 62.1284 37.784 62.5736 37.346 62.925C36.2749 63.6861 35.3934 64.6836 34.7699 65.8402C34.1463 66.9968 33.7973 68.2814 33.75 69.5945V70C33.7515 71.6572 34.4104 73.246 35.5822 74.4178C36.754 75.5896 38.3428 76.2485 40 76.25H100C104.31 76.25 108.443 74.5379 111.49 71.4905C114.538 68.443 116.25 64.3098 116.25 60C116.25 55.6902 114.538 51.557 111.49 48.5095C108.443 45.4621 104.31 43.75 100 43.75Z"
      fill="#E7EFF7"
    />
    <path
      d="M58.75 76.25H40C38.3424 76.25 36.7527 75.5915 35.5806 74.4194C34.4085 73.2473 33.75 71.6576 33.75 70V69.6C33.7921 68.288 34.1393 67.004 34.7642 65.8496C35.3891 64.6953 36.2745 63.7025 37.35 62.95C37.7882 62.5935 38.1413 62.1437 38.3836 61.6333C38.6258 61.1229 38.751 60.5649 38.75 60C38.7559 59.4414 38.6327 58.889 38.3898 58.386C38.1469 57.8829 37.7911 57.4428 37.35 57.1C36.2768 56.3336 35.3941 55.3308 34.7699 54.1691C34.1457 53.0075 33.7968 51.7179 33.75 50.4V50C33.75 48.3424 34.4085 46.7527 35.5806 45.5806C36.7527 44.4085 38.3424 43.75 40 43.75H58.75C57.0924 43.75 55.5027 44.4085 54.3306 45.5806C53.1585 46.7527 52.5 48.3424 52.5 50V50.4C52.5468 51.7179 52.8957 53.0075 53.5199 54.1691C54.1441 55.3308 55.0268 56.3336 56.1 57.1C56.5411 57.4428 56.8969 57.8829 57.1398 58.386C57.3827 58.889 57.5059 59.4414 57.5 60C57.501 60.5649 57.3758 61.1229 57.1336 61.6333C56.8913 62.1437 56.5382 62.5935 56.1 62.95C55.0245 63.7025 54.1391 64.6953 53.5142 65.8496C52.8893 67.004 52.5421 68.288 52.5 69.6V70C52.5 71.6576 53.1585 73.2473 54.3306 74.4194C55.5027 75.5915 57.0924 76.25 58.75 76.25Z"
      fill="#DAE4EF"
    />
    <path
      d="M100 78.75H40C38.3428 78.7515 36.754 79.4104 35.5822 80.5822C34.4104 81.754 33.7515 83.3428 33.75 85V85.4055C33.7973 86.7184 34.146 88.0028 34.7693 89.1593C35.3925 90.3159 36.2735 91.3135 37.344 92.075C37.7827 92.4259 38.1369 92.871 38.3803 93.3773C38.6237 93.8836 38.75 94.4382 38.75 95C38.75 95.5618 38.6237 96.1164 38.3803 96.6227C38.1369 97.129 37.7827 97.5741 37.344 97.925C36.2735 98.6865 35.3925 99.6841 34.7693 100.841C34.146 101.997 33.7973 103.282 33.75 104.594V105C33.7515 106.657 34.4104 108.246 35.5822 109.418C36.754 110.59 38.3428 111.249 40 111.25H100C104.31 111.25 108.443 109.538 111.49 106.49C114.538 103.443 116.25 99.3098 116.25 95C116.25 90.6902 114.538 86.557 111.49 83.5095C108.443 80.462 104.31 78.75 100 78.75Z"
      fill="#E7EFF7"
    />
    <path
      d="M58.75 111.25H40C38.3424 111.25 36.7527 110.592 35.5806 109.419C34.4085 108.247 33.75 106.658 33.75 105V104.6C33.7921 103.288 34.1393 102.004 34.7642 100.85C35.3891 99.6953 36.2745 98.7025 37.35 97.95C37.7882 97.5935 38.1413 97.1437 38.3836 96.6333C38.6258 96.1229 38.751 95.5649 38.75 95C38.7559 94.4414 38.6327 93.889 38.3898 93.386C38.1469 92.8829 37.7911 92.4428 37.35 92.1C36.2768 91.3336 35.3941 90.3308 34.7699 89.1691C34.1457 88.0075 33.7968 86.7179 33.75 85.4V85C33.75 83.3424 34.4085 81.7527 35.5806 80.5806C36.7527 79.4085 38.3424 78.75 40 78.75H58.75C57.0924 78.75 55.5027 79.4085 54.3306 80.5806C53.1585 81.7527 52.5 83.3424 52.5 85V85.4C52.5468 86.7179 52.8957 88.0075 53.5199 89.1691C54.1441 90.3308 55.0268 91.3336 56.1 92.1C56.5411 92.4428 56.8969 92.8829 57.1398 93.386C57.3827 93.889 57.5059 94.4414 57.5 95C57.501 95.5649 57.3758 96.1229 57.1336 96.6333C56.8913 97.1437 56.5382 97.5935 56.1 97.95C55.0245 98.7025 54.1391 99.6953 53.5142 100.85C52.8893 102.004 52.5421 103.288 52.5 104.6V105C52.5 106.658 53.1585 108.247 54.3306 109.419C55.5027 110.592 57.0924 111.25 58.75 111.25Z"
      fill="#DAE4EF"
    />
    <path
      d="M32.5 28.75C34.5711 28.75 36.25 27.0711 36.25 25C36.25 22.9289 34.5711 21.25 32.5 21.25C30.4289 21.25 28.75 22.9289 28.75 25C28.75 27.0711 30.4289 28.75 32.5 28.75Z"
      fill="#B5C1D4"
    />
    <path
      d="M32.5 63.75C34.5711 63.75 36.25 62.0711 36.25 60C36.25 57.9289 34.5711 56.25 32.5 56.25C30.4289 56.25 28.75 57.9289 28.75 60C28.75 62.0711 30.4289 63.75 32.5 63.75Z"
      fill="#B5C1D4"
    />
    <path
      d="M32.5 98.75C34.5711 98.75 36.25 97.0711 36.25 95C36.25 92.9289 34.5711 91.25 32.5 91.25C30.4289 91.25 28.75 92.9289 28.75 95C28.75 97.0711 30.4289 98.75 32.5 98.75Z"
      fill="#B5C1D4"
    />
    <path
      d="M50 21.25H70C70.3315 21.25 70.6495 21.1183 70.8839 20.8839C71.1183 20.6495 71.25 20.3315 71.25 20C71.25 19.6685 71.1183 19.3505 70.8839 19.1161C70.6495 18.8817 70.3315 18.75 70 18.75H50C49.6685 18.75 49.3505 18.8817 49.1161 19.1161C48.8817 19.3505 48.75 19.6685 48.75 20C48.75 20.3315 48.8817 20.6495 49.1161 20.8839C49.3505 21.1183 49.6685 21.25 50 21.25Z"
      fill="#A8B6CD"
    />
    <path
      d="M50 31.25H80C80.3315 31.25 80.6495 31.1183 80.8839 30.8839C81.1183 30.6495 81.25 30.3315 81.25 30C81.25 29.6685 81.1183 29.3505 80.8839 29.1161C80.6495 28.8817 80.3315 28.75 80 28.75H50C49.6685 28.75 49.3505 28.8817 49.1161 29.1161C48.8817 29.3505 48.75 29.6685 48.75 30C48.75 30.3315 48.8817 30.6495 49.1161 30.8839C49.3505 31.1183 49.6685 31.25 50 31.25Z"
      fill="#A8B6CD"
    />
    <path
      d="M50 26.25H60C60.3315 26.25 60.6495 26.1183 60.8839 25.8839C61.1183 25.6495 61.25 25.3315 61.25 25C61.25 24.6685 61.1183 24.3505 60.8839 24.1161C60.6495 23.8817 60.3315 23.75 60 23.75H50C49.6685 23.75 49.3505 23.8817 49.1161 24.1161C48.8817 24.3505 48.75 24.6685 48.75 25C48.75 25.3315 48.8817 25.6495 49.1161 25.8839C49.3505 26.1183 49.6685 26.25 50 26.25Z"
      fill="#A8B6CD"
    />
    <path
      d="M50 56.25H70C70.3315 56.25 70.6495 56.1183 70.8839 55.8839C71.1183 55.6495 71.25 55.3315 71.25 55C71.25 54.6685 71.1183 54.3505 70.8839 54.1161C70.6495 53.8817 70.3315 53.75 70 53.75H50C49.6685 53.75 49.3505 53.8817 49.1161 54.1161C48.8817 54.3505 48.75 54.6685 48.75 55C48.75 55.3315 48.8817 55.6495 49.1161 55.8839C49.3505 56.1183 49.6685 56.25 50 56.25Z"
      fill="#A8B6CD"
    />
    <path
      d="M80 63.75H50C49.6685 63.75 49.3505 63.8817 49.1161 64.1161C48.8817 64.3505 48.75 64.6685 48.75 65C48.75 65.3315 48.8817 65.6495 49.1161 65.8839C49.3505 66.1183 49.6685 66.25 50 66.25H80C80.3315 66.25 80.6495 66.1183 80.8839 65.8839C81.1183 65.6495 81.25 65.3315 81.25 65C81.25 64.6685 81.1183 64.3505 80.8839 64.1161C80.6495 63.8817 80.3315 63.75 80 63.75Z"
      fill="#A8B6CD"
    />
    <path
      d="M50 61.25H60C60.3315 61.25 60.6495 61.1183 60.8839 60.8839C61.1183 60.6495 61.25 60.3315 61.25 60C61.25 59.6685 61.1183 59.3505 60.8839 59.1161C60.6495 58.8817 60.3315 58.75 60 58.75H50C49.6685 58.75 49.3505 58.8817 49.1161 59.1161C48.8817 59.3505 48.75 59.6685 48.75 60C48.75 60.3315 48.8817 60.6495 49.1161 60.8839C49.3505 61.1183 49.6685 61.25 50 61.25Z"
      fill="#A8B6CD"
    />
    <path
      d="M50 91.25H70C70.3315 91.25 70.6495 91.1183 70.8839 90.8839C71.1183 90.6495 71.25 90.3315 71.25 90C71.25 89.6685 71.1183 89.3505 70.8839 89.1161C70.6495 88.8817 70.3315 88.75 70 88.75H50C49.6685 88.75 49.3505 88.8817 49.1161 89.1161C48.8817 89.3505 48.75 89.6685 48.75 90C48.75 90.3315 48.8817 90.6495 49.1161 90.8839C49.3505 91.1183 49.6685 91.25 50 91.25Z"
      fill="#A8B6CD"
    />
    <path
      d="M80 98.75H50C49.6685 98.75 49.3505 98.8817 49.1161 99.1161C48.8817 99.3505 48.75 99.6685 48.75 100C48.75 100.332 48.8817 100.649 49.1161 100.884C49.3505 101.118 49.6685 101.25 50 101.25H80C80.3315 101.25 80.6495 101.118 80.8839 100.884C81.1183 100.649 81.25 100.332 81.25 100C81.25 99.6685 81.1183 99.3505 80.8839 99.1161C80.6495 98.8817 80.3315 98.75 80 98.75Z"
      fill="#A8B6CD"
    />
    <path
      d="M50 96.25H60C60.3315 96.25 60.6495 96.1183 60.8839 95.8839C61.1183 95.6495 61.25 95.3315 61.25 95C61.25 94.6685 61.1183 94.3505 60.8839 94.1161C60.6495 93.8817 60.3315 93.75 60 93.75H50C49.6685 93.75 49.3505 93.8817 49.1161 94.1161C48.8817 94.3505 48.75 94.6685 48.75 95C48.75 95.3315 48.8817 95.6495 49.1161 95.8839C49.3505 96.1183 49.6685 96.25 50 96.25Z"
      fill="#A8B6CD"
    />
    <path
      d="M34.4 21.8C33.8322 21.4389 33.1729 21.248 32.5 21.25C31.5054 21.25 30.5516 21.6451 29.8483 22.3484C29.1451 23.0516 28.75 24.0055 28.75 25C28.75 25.9946 29.1451 26.9484 29.8483 27.6517C30.5516 28.3549 31.5054 28.75 32.5 28.75C33.1729 28.752 33.8322 28.5611 34.4 28.2C33.825 27.8866 33.345 27.424 33.0106 26.8609C32.6762 26.2977 32.4997 25.6549 32.4997 25C32.4997 24.3451 32.6762 23.7023 33.0106 23.1392C33.345 22.5761 33.825 22.1135 34.4 21.8Z"
      fill="#6C7B8F"
    />
    <path
      d="M34.4 56.8C33.8322 56.4389 33.1729 56.248 32.5 56.25C31.5054 56.25 30.5516 56.6451 29.8483 57.3484C29.1451 58.0516 28.75 59.0055 28.75 60C28.75 60.9946 29.1451 61.9484 29.8483 62.6517C30.5516 63.3549 31.5054 63.75 32.5 63.75C33.1729 63.752 33.8322 63.5611 34.4 63.2C33.825 62.8866 33.345 62.424 33.0106 61.8609C32.6762 61.2977 32.4997 60.6549 32.4997 60C32.4997 59.3451 32.6762 58.7023 33.0106 58.1392C33.345 57.5761 33.825 57.1135 34.4 56.8Z"
      fill="#6C7B8F"
    />
    <path
      d="M32.5 95C32.4912 94.3434 32.664 93.6971 32.9993 93.1324C33.3346 92.5678 33.8193 92.1067 34.4 91.8C33.8322 91.4389 33.1729 91.248 32.5 91.25C31.5054 91.25 30.5516 91.6451 29.8483 92.3484C29.1451 93.0516 28.75 94.0055 28.75 95C28.75 95.9946 29.1451 96.9484 29.8483 97.6517C30.5516 98.3549 31.5054 98.75 32.5 98.75C33.1729 98.752 33.8322 98.5611 34.4 98.2C33.8193 97.8934 33.3346 97.4323 32.9993 96.8676C32.664 96.303 32.4912 95.6567 32.5 95Z"
      fill="#6C7B8F"
    />
    <path
      d="M22.5 25C22.413 26.2019 22.7298 27.3985 23.4 28.4C23.1487 28.686 22.8418 28.9177 22.4979 29.0811C22.154 29.2444 21.7804 29.3359 21.4 29.35C21.1275 29.3386 20.8581 29.2881 20.6 29.2C19.4 28.7 18.75 27.2 18.75 25C18.75 22.8 19.4 21.3 20.6 20.8C21.0921 20.6266 21.6259 20.6104 22.1275 20.7537C22.6291 20.8971 23.0738 21.1928 23.4 21.6C22.7298 22.6015 22.413 23.798 22.5 25Z"
      fill="#DAE4EF"
    />
    <path
      d="M22.5 60C22.413 61.2019 22.7298 62.3985 23.4 63.4C23.1487 63.686 22.8418 63.9177 22.4979 64.0811C22.154 64.2444 21.7804 64.3359 21.4 64.35C21.1275 64.3386 20.8581 64.2881 20.6 64.2C19.4 63.7 18.75 62.2 18.75 60C18.75 57.8 19.4 56.3 20.6 55.8C21.0921 55.6266 21.6259 55.6104 22.1275 55.7537C22.6291 55.8971 23.0738 56.1928 23.4 56.6C22.7298 57.6015 22.413 58.798 22.5 60Z"
      fill="#DAE4EF"
    />
    <path
      d="M22.5 95C22.413 96.2019 22.7298 97.3985 23.4 98.4C23.1487 98.686 22.8418 98.9177 22.4979 99.0811C22.154 99.2444 21.7804 99.3359 21.4 99.35C21.1275 99.3386 20.8581 99.2881 20.6 99.2C19.4 98.7 18.75 97.2 18.75 95C18.75 92.8 19.4 91.3 20.6 90.8C21.0921 90.6266 21.6259 90.6104 22.1275 90.7537C22.6291 90.8971 23.0738 91.1928 23.4 91.6C22.7298 92.6015 22.413 93.798 22.5 95Z"
      fill="#DAE4EF"
    />
  </BaseIcon>
);

export { SummarizePlaceholder };
