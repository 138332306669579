import { FC } from 'react';
import { SvgIconProps } from '@mui/material';

import { BaseIcon } from '../base-icon/base-icon';

const Close: FC<SvgIconProps> = props => (
  <BaseIcon {...props}>
    <path
      d="M18 6L6 18"
      stroke="#A8B6CD"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 6L18 18"
      stroke="#A8B6CD"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </BaseIcon>
);

export { Close };
